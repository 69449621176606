import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/Index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Login } from "./components/login/login";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Login />,
	},
	{
		path: "/login",
		element: <Login />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<div className={"outerDiv"}>
			<div className={"auth-wrapper"}>
				<div className={"auth-inner"}>
					<RouterProvider router={router} />
				</div>
			</div>
		</div>
	</React.StrictMode>
);
